// 
// horizontal-nav.scss
//

.topnav-navbar {
  padding: 0;
    margin: 0;
    min-height: 70px;
    position: relative;
    left: 0 !important;
    z-index: 1002;

  .topnav-logo {
      line-height: 70px;
      float: left;
      padding-right: 20px;
      min-width: 200px;

    .topnav-logo-sm {
        display: none;
      }
    }

    .navbar-toggle {
      position: relative;
      cursor: pointer;
      float: left;
      margin: 27px 20px;
      padding: 0;
      background-color: transparent;
      border: none;

    .lines {
        width: 25px;
        display: block;
        position: relative;
        height: 16px;
        transition: all .5s ease;
      }

        span {
          height: 2px;
          width: 100%;
          background-color: rgba($dark, 0.8);
          display: block;
          margin-bottom: 5px;
          transition: transform .5s ease;
        }

        &.open {
          span {
            position: absolute;

        &:first-child {
            top: 7px;
            transform: rotate(45deg);
          }

                &:nth-child(2) {
                  visibility: hidden;
                }

                &:last-child {
                  width: 100%;
                  top: 7px;
                  transform: rotate(-45deg);
                }
                }
                }
                }

    .app-search {
      float: left;
    }
}

.topnav {
  background: $bg-topnav;

  .topnav-menu {
      margin: 0;
      padding: 0;
    }

    .navbar-nav {


    .nav-link {
        font-size: 22px;
        position: relative;
        padding: 0.4rem 1.3rem;
        border: none;
        background-color: transparent;



      &.custom {
          font-size: 22px;
        }
      }
      }

    .nav-item {
      &.active {
        >a {
          color: $menu-item-light-active !important;
        }
      }
    }

    .navbar-dark {
      .dropdown {

      &.active,
        &:active {
          >.nav-link {
            color: $white !important;
          }
        }
      }
      }
}

.arrow-down {
  display: inline-block;

  &:after {
      border-color: initial;
      border-style: solid;
      border-width: 0 0 1px 1px;
      content: "";
      height: .4em;
      display: inline-block;
      right: 5px;
      top: 50%;
      margin-left: 10px;
      transform: rotate(-45deg) translateY(-50%);
      transform-origin: top;
      transition: all .3s ease-out;
      width: .4em;
    }
}

body[data-layout="topnav"] {
  .container-fluid {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
    }
}

@include media-breakpoint-up(lg) {
  body[data-layout="topnav"] {
      .container-fluid {
        max-width: 95%;
      }

        &[data-layout-mode="boxed"] {
          .container-fluid {
            max-width: 97%;
          }
        }
        }

    .topnav {
      .navbar-nav {
        .align-right {
          margin-left: auto;
        }

            .align-left {
              margin-right: auto;
            }

            .nav-item {
              &:first-of-type {
                .nav-link {
                  padding-left: 15px;
                  padding-right: 15px;
                }
              }
            }
            }

        .dropdown {
          .dropdown-menu {
            margin-top: 0;
            border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
            min-width: calc(#{$dropdown-min-width} + 1.5rem);
            @include font-size(calc(#{$dropdown-font-size} - 0.01rem));

                .arrow-down {
                  &::after {
                    right: 15px;
                    transform: rotate(-135deg) translateY(-50%);
                    position: absolute;
                  }
                }

                .dropdown {
                  .dropdown-menu {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    display: none
                  }
                }
                }

            &:hover {
              >.dropdown-menu {
                display: block;
              }
            }
            }

        .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
          display: block
        }
        }

    .dropdown.active>a.dropdown-item {
      color: $dropdown-link-active-color;
      @include gradient-bg($dropdown-link-active-bg);
    }
}

@include media-breakpoint-up(xxl) {
  body[data-layout="topnav"] {
      .container-fluid {
        max-width: 85%;
      }
    }
}

@include media-breakpoint-up(lg) {
  .navbar-toggle {
      display: none;
    }
}

@include media-breakpoint-down(lg) {
  .topnav-navbar {
      .topnav-logo-lg {
        display: none;
      }

        .topnav-logo {
          min-width: 50px;
          padding-right: 0;
          text-align: center;
        }

        .topnav-logo-sm {
          display: block !important;
        }
        }

    .topnav {
      .navbar-nav {
        .nav-link {
          padding: 0.75rem 1.3rem;
        }
      }

        .dropdown {
          .dropdown-menu {
            background-color: transparent;
            border: none;
            box-shadow: none;
            padding-left: 15px;
          }

            .dropdown-item {
              position: relative;
              background-color: transparent;
            }
            }

        .navbar-dark {
          .dropdown {
            .dropdown-item {
              color: rgba($white, 0.5);

          &.active,
            &:active {
              color: $white;
            }
          }
          }
          }

        .arrow-down {
          &::after {
            right: 15px;
            position: absolute;
          }
        }
        }
}