// 
// custom-forms.scss
//


@each $color,
$value in $theme-colors {

  .form-checkbox-#{$color},
    .form-radio-#{$color} {
      .form-check-input {
        user-select: none;

      &:checked {
          background-color: $value;
          border-color: $value;
        }
      }
      }
}