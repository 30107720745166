// 
// modal.scss
//

// Title text within header
.modal-title {
  margin-top: 0;
}

// Modal full width
.modal-full-width {
  width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
  margin: 0 auto;
}

// Right Modal
.modal-right {
  position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%, 0) !important;

  button.btn-close {
      position: fixed;
      top: 20px;
      right: 20px;
      z-index: 1;
    }
}

.modal {
  &.show {

    .modal-right,
      .modal-left {
        transform: translate(0, 0) !important;
      }
    }
}

// Bottom modal
.modal-bottom {
  display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
  color: $white;
    border-radius: 0;
    background-color: #0047ab;
    // background: -moz-linear-gradient(45deg, #0059d6 0, #0047AB 100%);
    // background: -webkit-linear-gradient(45deg, #0059d6, #0047AB);
    // background: -o-linear-gradient(45deg, #0059d6 0, #0047AB 100%);
    // background: linear-gradient(45deg, #0059d6, #0047AB);

  .btn-close {
      color: $white !important;
    }

    .trip-modal-action {
      opacity: 0.65;
        color: $white !important;

    &:hover {
        opacity: 0.8;
      }
    }
}

// Full filled modals
.modal-filled {
  color: $white;

  .modal-header {
      background-color: rgba($white, 0.07);
    }

    .modal-header,
    .modal-footer {
      border: none;
    }

    .btn-close {
      color: $white !important;
    }
}


// Model
.modal-open {
  padding-right: 0px !important;
    padding-left: 0px !important;
}

.modal-dialog {
  margin: 3.5rem auto !important;
    max-width: 600px !important;
}

@media only screen and (max-width: 600px) {
  .modal-dialog {
      margin: 0 auto !important;
      max-width: 600px !important;
    }
}

.modal-content {
  border: none;
}