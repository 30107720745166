//
// frappe-gantt.scss
//


@import 'frappe-gantt/src/gantt.scss';

.gantt {
  .bar-progress {
      fill: $primary !important;
    }

    .bar-wrapper:hover {
      .bar-progress {
        fill: lighten($primary, 5%) !important;
      }
    }
.bar,
.bar-wrapper:hover .bar {
  fill: $progress-bg !important;
}
.bar-label,
.bar-label.big {
  fill: $gray-700 !important;
}

    .grid-header {
      fill: $table-hover-bg !important;
      stroke: $table-hover-bg !important;
    }

    .grid-row {
      fill: transparent !important;

    &:nth-child(even) {
        fill: $table-hover-bg !important;
      }
    }
.lower-text,
.upper-text {
  fill: $table-color !important;
}

    .row-line {
      stroke: $table-border-color !important;
    }

    .today-highlight {
      fill: $light !important;
    }
}

.gantt-container {
  .popup-wrapper {
      .pointer {
        display: none;
      }
    }
}