/*the container must be positioned relative:*/
.autocomplete {
  // position: relative;
    display: inline-block;
}

.autocomplete-items {
  position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    text-align: left;
    line-height: normal;
    /*position the autocomplete items to be the same width as the container:*/
    //top: 100%;
    //left: 0;

  border-top: 1px solid #d4d4d4;
}

.autocomplete-items div {
  padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
    color: #ffffff;
}