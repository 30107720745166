// .react-select{
//     min-width: 180px;
//     &.open{
//         width: 180px;
//     }
// }
/* Chrome, Safari, Edge, Opera */
input.price-cell::-webkit-outer-spin-button,
input.price-cell::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].price-cell {
  -moz-appearance: textfield;
}

body[data-layout="topnav"] .container-fluid,
body[data-layout="topnav"] .container-sm,
body[data-layout="topnav"] .container-md,
body[data-layout="topnav"] .container-lg,
body[data-layout="topnav"] .container-xl,
body[data-layout="topnav"] .container-xxl {
  max-width: 98%;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dispatchIcon {
  margin: 3px;
  font-size: 24px;

  &:hover {
    opacity: 0.5;
  }
}

.status-dropdown {
  display: inline-block;

  // padding: 0 3px;
  .status-dropdown-item {
    height: 50px;
  }
}

.form-control {
  padding: 0.45rem 0.45rem;
}

.labeled-form-control {
  display: inline-block;
  width: calc(100% - 2em);
}

.table-cell {
  border-color: #fff;
}

.navbar-expand-lg .navbar-nav {
  flex-direction: row !important;
}

#topnav-menu-content {
  display: block !important;
}

input {
  border: 1px solid #f1f1f1;
  padding: 10px;
  font-size: 16px;
}

input[type="submit"] {
  background-color: DodgerBlue;
  color: #fff;
  cursor: pointer;
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll-bar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.clone {
  display: inline-block;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  width: 20px;
}

.dispatch-container {
  // background-color: #eee;
  border-radius: 20px;
  box-shadow:
    inset #999 0 0 2px,
    inset #999 2px 0 3px;
  padding: 2px;
  padding-top: 4px;
  transition: box-shadow 0.2s ease-in;
}

.invoice-container {
  // border-radius: 50px;
  padding: 20px;
}

@media only screen {
  .scrollable-invoice {
    max-height: 400px;
    overflow-y: auto;
  }
}

.dispatch-container:hover .priority-dispatch {
  //visibility: visible;
  transition: opacity 0.4s ease 0.5s;
  opacity: 1;
  display: inline;
}

.priority-dispatch {
  //visibility: hidden;
  transition: opacity 0.4s ease 0.5s;
  opacity: 0;
  display: none;
}

// .dash-table td:hover {
//   box-shadow: inset 0 0 2px #000;
// }
.customers-table {
  & tbody tr:hover {
    background-color: #e1e1e1 !important;
    cursor: pointer;
  }

  & tr.even {
    background-color: #f2f2f2;
  }
}

.invoice-table {
  & tbody tr:hover {
    background-color: #e1e1e1 !important;
    cursor: pointer;
  }
}

.dash-table {
  & tr:hover {
    background-color: #e1e1e1;

    & input:not([type="checkbox"]):not([disabled]),
    textarea {
      background-color: #e1e1e1;
      border: #e1e1e1;
    }

    & input {
      border: solid 1px #e1e1e1;
    }
  }

  & tr.even {
    background-color: #f2f2f2;
  }
}

.floatingLabel {
  opacity: 0.6;
}

.tableTop {
  display: flex;
  margin-bottom: 12px;
}

.bg-indigo {
  background-color: $indigo;
}

span.disabled {
  background-color: #eef2f7;
  opacity: 1;
}

.pointer input {
  cursor: pointer;
}

.mdi-currency-usd {
  font-size: 20px;
}

.link-btn {
  text-align: right;
  color: #0047ab;
  cursor: pointer;
  padding: 5px;
  background-color: transparent;
  border: none;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    color: #999;
  }

  &:disabled:hover {
    opacity: 1;
  }
}

.pac-container.pac-logo {
  z-index: 100000 !important;
  display: block;
}

.multiselect-actions {
  align-items: center;
}

.close-button {
  position: absolute;
  top: 100px;
  right: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Toastify__toast-container--top-right {
  top: 5em !important; //under navbar
}

.pagination-rounded button[disabled]:hover {
  background-color: unset;
}

.modal-invoice-billing {
  min-width: 1000px !important;
  width: 1000px !important;
}

.custom-btn-hover:hover {
  box-shadow: 0px 0px 3px 2px #aab1b7;
}

.btn-hover-white:hover {
  color: white;
}

.driver-hover-effect:hover {
  background-color: #dbdbdb !important;
  border-radius: 15px;

  .count-badge {
    background-color: #39afd1 !important;
  }
}

.sugg-cont {
  border: transparent !important;
  border-radius: 0px 0px 0.25rem 0.25rem !important;
  width: 548px;
  // min-height: 200px;
  z-index: 1002;
  position: fixed;
  box-shadow: 0px 5px 4px rgb(192, 189, 189);

  .more-btn {
    border-radius: 0px 0px 0.25rem 0.25rem !important;
  }
}

.all-trips-search {
  .search-bar {
    border-radius: 0.25rem 0rem 0rem 0.25rem !important;
  }

  .clear-btn {
    border: 1px solid #dee2e6;
    border-left: none !important;
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;
  }

  .search-btn {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem !important;
  }
}

.suggestion-item {
  padding: 5px 5px 2px 5px;
  font-size: 13px;
  height: 45px;

  .sugg-status {
    font-size: 12px;
    padding: 3px 4px;
    border-radius: 0.25rem !important;
  }

  .sugg-number {
    font-size: 12px;
    padding: 1px 2px;
    border-radius: 0.25rem !important;
  }

  .sugg-copy {
    &:hover {
      color: #6388d3 !important;
    }
  }

  &:last-child {
    border-radius: 0px !important;
  }

  &:hover {
    background-color: #eceff3 !important;
    cursor: pointer;
  }
}

.zone-badge {
  width: auto;
  font-size: 11px !important;

  &.left-zone-badge {
    border-radius: 0.25rem 0rem 0rem 0.25rem !important;
  }

  &.right-zone-badge {
    border-radius: 0rem 0.25rem 0.25rem 0rem !important;
  }
}

.driver-info-card {
  .badge.rounded-pill {
    border-radius: 50rem !important;
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
    padding: 0.375rem !important;
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
    font-size: 13px;
  }
}

// .invoice-modal-card p span {
//     margin: 0px 12px;
// }
.tab-card-box-shadow {
  box-shadow: 0px 20px 20px 0px rgba(154, 161, 171, 0.15);
}

.custom-label-margin {
  .form-check-label {
    margin: 3px !important;
  }
}

.invoice-items-table {
  .lh-invoice {
    line-height: 1.8;
  }

  td {
    vertical-align: middle !important;
  }

  td:nth-of-type(1) {
    width: 150px !important;
    padding-right: 5px;
  }

  td:nth-of-type(2) {
    min-width: 230px !important;
    width: 230px !important;
  }

  td:nth-of-type(3) {
    min-width: 230px !important;
    width: 230px !important;
  }

  td:nth-of-type(4) {
    min-width: 60px !important;
    padding-right: 8px;
  }

  td:nth-of-type(5) {
    min-width: 115px !important;
  }

  td:nth-of-type(6) {
    min-width: 60px !important;
    padding-right: 10px;
  }

  @media print {
    td {
      vertical-align: unset !important;
    }

    td:nth-of-type(1) {
      width: 150px !important;
    }

    td:nth-of-type(2) {
      min-width: 230px !important;
      width: 230px !important;
    }

    td:nth-of-type(3) {
      min-width: 230px !important;
      width: 230px !important;
    }

    td:nth-of-type(4) {
      min-width: 60px !important;
    }

    td:nth-of-type(5) {
      min-width: 80px !important;
    }
  }

  &.line-items {
    td {
      vertical-align: middle !important;
    }

    td:nth-of-type(1) {
      width: 714px !important;
    }

    td:nth-of-type(2) {
      width: 110px !important;
    }

    td:nth-of-type(3) {
      width: 100px !important;
      padding-right: 15px;
    }

    @media print {
      td:nth-of-type(1) {
        min-width: 505px !important;
        width: 505px !important;
      }

      td:nth-of-type(2) {
        width: 110px !important;
        min-width: 110px !important;
      }

      td:nth-of-type(3) {
        display: none;
      }
    }
  }
}

.payments-table {
  td:nth-of-type(1) {
    min-width: 120px !important;
  }

  td:nth-of-type(2) {
    min-width: 160px !important;
  }

  td:nth-of-type(3) {
    min-width: 100px !important;
  }

  td:nth-of-type(4) {
    width: auto !important;
    white-space: normal !important;
    font-size: 13px;
    line-height: 1.6;
    padding-right: 40px;
  }

  td:nth-of-type(5) {
    min-width: 100px !important;
  }
}

@page {
  margin: 10mm 3mm;
}

@media print {
  .link-to-user {
    display: none !important;
  }

  tbody {
    break-inside: auto !important;

    .invoice-delete-item {
      display: none !important;
    }
  }

  .payment-info {
    break-inside: avoid !important;
  }
}

.text-under-logo {
  font-size: 39px;
  font-family: "Baloo 2", cursive;
  font-weight: 500;
}

.header {
  text-align: center;
  border-radius: 3px 3px 0 0;
  font-weight: bold;
  background-color: #0047ab;
  padding: 25px;
  color: #fff;
  font-size: 20px;
}

// .form-input-custom:hover {
//     border-color: transparent;
//     box-shadow: 0 0 0 1px #b9d6ff;
// }